import { createApp, defineAsyncComponent} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '../src/main.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
const PostCard = defineAsyncComponent(() => import('@/components/CardPost/postCard.vue'))
const FilterCard = defineAsyncComponent(() => import('@/components/jobs/FilterCard.vue'))
const Pagination = defineAsyncComponent(() => import('@/components/pagination/pagination.vue'))
const Search = defineAsyncComponent(() => import('@/components/search/search.vue'))

createApp(App).use(store).use(router).use(VueAxios, axios).component('filter-card', FilterCard, ).component('post-card', PostCard, ).component('pagination', Pagination, ).component('search', Search, ).mount('#app')
