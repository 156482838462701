<template>
  <div class="bg-gray-100 dark:bg-slate-900">
    <Navbar v-if="$route.name !=='redirect'" />
    <router-view/>
    <Footer v-if="$route.name !=='redirect' && $route.name !== '404'" />
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue';
export default {
    components: {
        Navbar,
        Footer
    }
}
</script>