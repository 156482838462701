import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home/index.vue'
import Draft from '../pages/Draft/index.vue'
import PassTrip from '../pages/PassTrip/index.vue'
import PerfTrip from '../pages/PerfTrip/PerfTrip.vue'
import PerfTripDetail from '../pages/PerfTrip/detail.vue'
import PassTripDetail from '../pages/PassTrip/detail.vue'
import Error404 from '../components/error/404/index.vue'
import Blog from '../pages/Blog/index.vue'
import BlogDetail from '../pages/Blog/blogDetail.vue'
import Jobs from '../pages/Jobs/index.vue'
import JobDetail from '../pages/Jobs/jobDetail.vue'
import SearchTrips from '../pages/SearchTrips/index.vue'
import Redirect from '../pages/Redirect/index.vue'
import Test from '../pages/Test/index.vue'
import  NotFound from '../views/NotFound'
import Legal from '../views/Legal'
import LegalRU from '../views/LegalRU'
import LegalInfo from '../views/LeagalTJ'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/draft',
    name: 'Draft',
    component: Draft
  },
  {
    path: '/passTrip',
    name: 'PassTrip',
    component: PassTrip
  },
  {
    path: '/perfTrip',
    name: 'PerfTrip',
    component: PerfTrip
  },
  {
    path: '/performer/:id',
    name: 'PerfTripDetail',
    component: PerfTripDetail
  },
  {
    path: '/passenger/:id',
    name: 'PassengerTripDetail',
    component: PassTripDetail
  },
  {
    path: '/service',
    name: 'Service',
    component: Error404
  },
  {
    path: '/about',
    name: 'about',
    component: Error404
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/blogDetail/:id',
    name: 'blogDetail',
    component: BlogDetail
  },
  {
    path: '/jobs',  
    name: 'jobs',
    component: Jobs
  },
  {
    path: '/jobDetail/:id',
    name: 'jobDetail',
    component: JobDetail
  },
  {
    path: '/searchTrips',
    name: 'searchTrips',
    component: SearchTrips
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: Redirect
  },
  { 
    path: '/404', 
    name: '404', 
    component: NotFound, 
  },
  { 
    path: '/legal', 
    name: 'legal', 
    component: Legal,
  },
  {
    path: '/legal/ru',
    name: 'legalRU',
    component: LegalRU
  },
  { 
    path: '/legal/tj', 
    name: 'legalTJ', 
    component: LegalInfo, 
  },
  { 
    path: '/:catchAll(.*)',
    redirect: '/404' 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
