<template>
    <div class="xl:container mx-auto font-sans lg:px-8 px-2 md:py-16 py-10">
        <h1 class="sm:text-[56px] text-3xl md:mb-16 mb-12 dark:text-gray-100">С чего начать?</h1>
        <div class="flex justify-between items-center">
            <div class="md:ml-14 sm:ml-4">
            <ul class="mb-4 dark:text-gray-100">
                <li class="flex pb-9">
                    <p class="text-sm border-2 border-black dark:border-gray-100 rounded-full md:w-7 sm:w-7 w-10 h-7 flex items-center justify-center mr-4 cursor-pointer">
                        1
                    </p>
                    <div class="text-lg cursor-pointer">
                        <h4 class="font-bold text-xl">Перейдите в телеграм</h4>
                        <p class="text-[16px]">В поиске напишите <b> <a class="text-blue-500" href="https://t.me/hamroh_gram_bot">hamroh_gram_bot</a> </b> или же нажмите на кнопку ниже, и запустите бота.</p>
                    </div>
                </li>
                <li class="flex pb-9 ">
                    <p class="text-sm border-2 border-black dark:border-gray-100 rounded-full md:w-7 sm:w-7 w-7 h-7 flex items-center justify-center mr-4 cursor-pointer">
                        2
                    </p>
                    <div class="text-lg text-black cursor-pointer">
                        <h4 class="font-bold text-xl">Пройдите авторизацию</h4>
                        <p class="text-[16px]">Введите номер телефона и подтвердите его кодом</p>
                    </div>
                </li>
                <li class="flex pb-9">
                    <p class="text-sm border-2 border-black dark:border-gray-100 rounded-full md:w-7 sm:w-7 w-7 h-7 flex items-center justify-center mr-4 cursor-pointer">
                        3
                    </p>
                    <div class="text-lg text-black cursor-pointer">
                        <h4 class="font-bold text-xl">Перейдите в канал со списком поездок</h4>
                        <p class="text-[16px]">Найдите поездку подходящую вам по маршруту</p>
                    </div>
                </li>
                <li class="flex pb-9">
                    <p class="text-sm border-2 border-black dark:border-gray-100 rounded-full md:w-7 sm:w-7 w-10 h-7 flex items-center justify-center mr-4 cursor-pointer">
                        4
                    </p>
                    <div class="text-lg text-black  cursor-pointer">
                        <h4 class="font-bold text-xl">Оформите поездку с помощью бота</h4>
                        <p class="text-[16px]">Если не нашли подходящую поездку то опубуликуйте  его в канале выбрав маршрут, цену, дату и время.</p>
                    </div>
                </li>
            </ul>
            <a href="https://t.me/hamroh_gram_bot">
                <div class="hover:bg-sky-600 active:bg-sky-700 text-[16px] bg-[#07CAFF] inline md:py-6 py-4 md:px-16 px-8 rounded-2xl cursor-pointer">
                    Перейти на телеграм бот
                </div>
            </a>
            </div>
            <div class="lg:block hidden">
                <img src="@/assets/introd.svg" alt="" class="dark:filter dark:invert dark:hue-rotate-180">
            </div>
        </div>
    </div>
</template>